body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 -.25rem .75rem #0000000d;
    color: #000;
    font-size: 10px;
    height: 0;
    left: 0;
    padding: 10px 10px 30px;
    position: fixed;
    right: 0;
    text-align: center;
    width: 100%;
}

.MuiDataGrid-root{
    /*To combat the footer as mui grid height calculation is not the same as responsive grids used elsewhere*/
    margin-bottom: 30px;
}
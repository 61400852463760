* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#root {
    font-family: EMprintRegular, HelveticaNeue, Helvetica, Arial, sans-serif;
    font-weight: 500 !important;
    font-style: normal;
    -webkit-text-size-adjust: 100%;
}

ol,
ul,
dl {
    margin-bottom: 0 !important;
}

li {
    list-style-type: none;
}

.nav-link {
    text-decoration: none;
}

nav {
    display: flex;
    justify-content: space-around;
    min-height: 10vh;
    align-items: center;
    font-family: EMprintRegular, HelveticaNeue, Helvetica, Arial, sans-serif;
    background: linear-gradient(to left, rgb(36, 198, 220), rgb(81, 74, 157));
    color: #fff;
    /* background: #3a397b;
    background-image: linear-gradient(45deg,#3a397b,#3190d9); */
}

nav .logo {
    border: none;
    font-family: EMprintRegular, HelveticaNeue, Helvetica, Arial, sans-serif;
    margin: 30px 0 !important;
    padding: 0 !important;
    font-size: 30px;
}

nav .nav-links {
    display: flex;
    justify-content: space-around;
    width: 35%;
}

nav .nav-links .nav-link {
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    font-family: EMprintRegular, HelveticaNeue, Helvetica, Arial, sans-serif;
}

.nav-link {
    color: #fff;
}

nav .nav-links .nav-link:hover {
    border-bottom: 1px solid #fff;
}

.textline {
    font-size: 40px;
    height: 150px;
    padding: auto;
    text-align: center;
}

nav .burger {
    height: 20px;
    width: 20px;
    cursor: pointer;
    display: none;
}

.content {
    display: flex;
    justify-content: space-around;
    min-height: 10vh;
    align-items: center;
    font-size: 30px;
    margin-top: 10%;
}

.md-content {
    background-color: aliceblue;
    text-align: center;
    font-weight: bold;
    margin: 100px;
    padding: 25px;
    /* border-radius: 30px; */
    color: rgb(81, 74, 157);
}

.md-content-1 {
    background-color: aliceblue;
    text-align: center;
    font-weight: bold;
    margin: 0px;
    padding: 25px;
    font-size: 15px;
    color: rgb(81, 74, 157);
}

.subMenu {
    width: 15rem;
    color: black;
    position: absolute;
    list-style: none;
    text-align: start;
    z-index: 999;
    /* right: 170px;
    top: 65px; */
    /* right: 185px; */
    top: 63px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

.subMenu1 {
    width: 20rem;
    color: black;
    position: absolute;
    list-style: none;
    text-align: start;
    z-index: 999;
    right: 50px;
    top: 65px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

.subMenu1.clicked {
    display: none;
}

ul .subMenu {
    padding-left: 0px !important;
}

.subMenu a {
    color: black !important;
}

.subMenu li {
    /* background: rgb(36, 198, 220); */
    cursor: pointer;
    padding-left: 16px;
    border: 1px solid #eee;
}

.subMenu.clicked {
    display: none;
}

.submenu-item {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    /* color: #fff; */
    padding: 16px;
    /* background: linear-gradient(to left, rgb(36, 198, 220), rgb(81, 74, 157));
    font-family: EMprintRegular,HelveticaNeue,Helvetica,Arial,sans-serif; */
    font-size: 14px;
}

.ipad1 {
    padding: 10px 20px;
}

.MuiTextField-root {
    width: 100% !important;
}

.mt-minus {
    margin-top: -8px !important;
}

.submenu-item:hover {
    /* background: #3a397b; */
    color: #3a397b !important;
    font-weight: bold;
}

@media all and (max-width:768px) {
    nav .burger {
        display: block;
    }

    nav .nav-links {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 100vh;
        background: linear-gradient(to top, rgb(36, 198, 220), rgb(81, 74, 157));
        padding: 40px;
        width: 50%;
        transform: translateX(-500px);
        transition: 0.5s ease-in-out;
        z-index: 999;
    }

    nav .nav-links li {
        padding: 25px;
        border-bottom: 1px solid #ccc;
    }

    nav .nav-links .nav-link:hover {
        border-bottom: 0px solid #fff;
    }
}
.container {
    margin-top: 5px;
    margin-left: 13%;
    margin-right: 5%;
}

.titleBar {
    padding: 18px;
    font-size: 20px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.gridLogo {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.selectCell {
    text-decoration: underline;
    cursor: pointer;
    color: rgb(36, 198, 220);
}

.csDialogTitle {
    border-bottom: 1px solid rgb(224 224 224);
    margin-bottom: 25px !important;
}

.csDialogbottom {
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(224 224 224);
}

.clearfix{
    padding: 15px;
}

.btn{
    width: 100%;
}

.btn-primary-label{
    margin-top: 12px;
    width: 80%;
}